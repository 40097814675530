.App {
  text-align: center;
}

.logo {
  width: 100px;
}
.main-banner {
  background-image: url(./img/banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 120px 50px;
}
.secondary-banner {
  background-image: url(./img/picture.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 120px 50px;
}

.cont-navbar{
  padding: 0px 394px;
}

.nav-text {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}
.title {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 47px;
  font-weight: 700;
  color: #fff;
  line-height: 65px;
}
.desc {
  text-align: left;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #fff;
  line-height: 20px;
}

.box{
  padding: 3px 10px;
  border: PowderBlue 5px solid;
  border-radius: 20px;
}

.box-vision{

  padding: 62px 0px;
}

.box-mision{
  padding: 62px 0px;
}

.footer-text {
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #a2a2a2;
  padding: 20px 0;
}
.align-items-left {
  text-align: left;
}
.align-items-right {
  text-align: right;
}

@media (max-width: 460px) {
  .logo {
    width: 80px;
  }
  .cont-navbar{
    padding: 0px 0px;
  }
  .main-banner {
    padding: 40px 20px;
  }
  .title {
    text-align: center;
    font-size: 30px;
    line-height: 35px;
  }
  .desc {
    text-align: center;
  }
  .align-items-left {
    text-align: center;
  }
  .align-items-right {
    text-align: center;
  }
  .footer-text {
    padding: 5px 0;
  }
  .footer {
    padding: 20px 0;
  }
}
